import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const PrivacyPolicy = () => (
  <Layout>
	<div className="privacy-policy legal">
		<h1>Privacy Policy</h1>
			<p>BML Ventures is committed to protecting the privacy of our website visitors and customers. This privacy policy outlines how we collect, use, and safeguard your personal information.</p>

			<h2>Information we collect</h2>
			<p>We may collect personal information that you voluntarily provide to us, such as your name, email address, phone number, and other contact information. We may also collect non-personal information, such as your IP address and web browser type, through the use of cookies and other tracking technologies.</p>

			<h2>How we use your information</h2>
			<p>We use the personal information you provide to us to communicate with you and respond to your inquiries. We may also use your information to improve our products and services, send promotional emails, and conduct market research.</p>

			<h2>How we share your information</h2>
			<p>We do not sell or rent your personal information to third parties. However, we may share your information with our trusted partners and service providers who assist us in operating our website, conducting our business, or servicing you.</p>

			<h2>How we protect your information</h2>
			<p>We take reasonable measures to safeguard your personal information from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee the absolute security of your information.</p>

			<h2>Your rights and choices</h2>
			<p>You have the right to request access to, correction, or deletion of your personal information. You may also opt-out of receiving promotional emails from us by following the unsubscribe instructions in the email. Please note that we may still need to communicate with you regarding your use of our products or services.</p>

			<h2>Changes to this policy</h2>
			<p>We may update this privacy policy from time to time to reflect changes in our practices or applicable laws. Your continued use of our website after the effective date of any updates to this policy constitutes your acceptance of such changes.</p>

			<h2>Contact us</h2>
			<p>If you have any questions or concerns about our privacy policy or our use of your personal information, please contact us at <a href="mailto:info@bmlventures.com">info@bmlventures.com</a>.</p>
	</div>
</Layout>
	
)

export const Head = () => <Seo title="Privacy"/>

export default PrivacyPolicy
